<template lang="html">
  <v-container fluid>
    <v-card icon="mdi-account" >
      <div v-if="tampilanHasil" max-height="100vh">
        <hasil-review :hasil="dataPegawai" :keterangan="keterangan"></hasil-review>
      </div>
      <div v-else max-height="100vh">
        <v-row id="pegawai" class="fixed-row">
          <v-col style="background-color: white;margin: 0px 0px 12px 12px;">
            <div class="ma-3 d-flex justify-center align-center flex-column">
              <v-avatar tile size="164"> <v-img :src="dataPegawai.data_pegawai.foto"></v-img></v-avatar>
              <h3 class="mt-1 orange--text text--darken-2 headline">{{dataPegawai.data_pegawai.nama_pegawai}}</h3>
              <div class="mt-1 grey--text">NIP. {{dataPegawai.data_pegawai.nip_pegawai}}</div>
              <div class="mt-1 green--text">{{dataPegawai.data_pegawai.jabatan}}</div>
            </div>
          </v-col>
          <v-col style="background-color: white;margin: 0px 12px 12px 0px;">
            <h2 class="display-2">Keterangan Pengisian</h2>
            <v-simple-table dense>
              <tbody>
                <tr  v-for="(ket,i) in keterangan" :key="i">
                  <td>{{ket.isi}}</td>
                  <td>{{ket.ket}}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
            <div v-for="(q,i) in groupQuesioner" :key="i" class="my-3">
              <h1 class="primary--text display-3 font-weight-bold" style="text-align:center;text-transform:uppercase">{{q.kelompok}}</h1>
              <v-simple-table>
                <tbody>
                  <template v-for="(a, j) in q.q">
                    <tr :key="j"><td v-html="a.narasi"></td></tr>
                    <tr :key="j+'b'" class="orange--text text--darken-2"><td>
                      <v-radio-group
                        row
                        v-model="radioAnswer[a.id]"
                        :rules="[v => (!isNaN(parseInt(v))) && v >= 0 && v <=6 || 'Pilih salah satu']"
                        required
                      >
                        <v-radio v-for="(ket,i) in keterangan"
                          :label="ket.isi"
                          :value="i"
                          :key="i"
                          @change="storeAnswer(a.id, i, a, a.narasi, q.kelompok)"
                        ></v-radio>
                      </v-radio-group>
                    </td></tr>
                  </template>
                </tbody>
              </v-simple-table>
            </div>
          </v-form>
          </v-col>
        </v-row>
      </div>
      <v-card-actions class="d-flex justify-center">
        <v-btn @click="closeEvent()">tutup</v-btn>
        <confirm-btn v-if="!dataPegawai.hasil_review" title="Konfirmasi" content="Yakin dengan jawaban anda?" name="Submit" :btnProps="btnProps" @on-agree='onAgree' />
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialogKeteranganJawaban" max-width="800px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Masukan alasan anda:</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formKeteranganJawaban">
            <v-row>
              <span><p style="font-size:16px">Kelompok Pertanyaan: &nbsp; </p></span><h3 class="primary--text font-weight-bold" style="text-align:center;text-transform:uppercase" v-html="formKeteranganJawaban.kelompok"></h3>
              <h3>Silahkan masukan contoh perilaku untuk saudara/i <b>{{dataPegawai.data_pegawai.nama_pegawai}}</b> yang menunjukan:</h3>
              <span><p style="font-size:16px" v-html="formKeteranganJawaban.pertanyaan"></p></span>
            </v-row>
            <v-row align="center">
              <v-col cols="12" align="center">
                <v-text hidden v-model="formKeteranganJawaban.id" label="Keterangan" outlined auto-grow></v-text>
                <v-text hidden v-model="formKeteranganJawaban.eselon" label="Keterangan" outlined auto-grow></v-text>
                <v-text hidden v-model="formKeteranganJawaban.nilai" label="Keterangan" outlined auto-grow></v-text>
                <v-text hidden v-model="formKeteranganJawaban.level" label="Keterangan" outlined auto-grow></v-text>
                <v-textarea :disabled="disabledSimpanKeterangan" v-model="formKeteranganJawaban.keterangan" :rules="[v => (v && v.length >= 10) || 'Minimal 10 karakter']" label="Keterangan" outlined auto-grow></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn @click="dialogKeteranganJawaban=false">Tutup</v-btn> -->
          <v-btn id="btn-simpan-keterangan" color="success" :disabled="disabledSimpanKeterangan" @click="simpanKeteranganJawaban()">Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash'
import store from '@/store'
import PelaksanaService from '@/services/PelaksanaService'
import ConfirmBtn from '@/components/base/ConfirmBtn'
import HasilReview from './components/HasilReview'
import {defaultRules} from '@/utils/lib'

export default {
  components: {
    ConfirmBtn,
    HasilReview
  },

  props:{
    dataPegawai:Object,
  },

  data(){
    return {
      btnProps:{
        name: 'submit'
      },
      valid:true,
      pegawai: {},
      keterangan:[
        {isi:'Tidak Pernah', ket:	'Sama sekali tidak pernah'},
        {isi:'Hampir tidak pernah', ket:	'Beberapa kali dalam setahun atau kurang'},
        {isi:'Jarang', ket:	'Sekali sebulan atau kurang'},
        {isi:'Kadang – kadang', ket:	'Beberapa kali sebulan'},
        {isi:'Sering', ket:	'Seminggu sekali'},
        {isi:'Sangat Sering', ket:	'Beberapa kali seminggu'},
        {isi:'Selalu', ket:	'Setiap hari'},
      ],
      groupQuesioner:[],
      quesioner:[],
      radioAnswer:[],
      idsQuestion:[],
      savedAnswers:[],
      dialogKeteranganJawaban: false,
      formKeteranganJawaban: {},
      disabledSimpanKeterangan: false,
      rules:{
        ...defaultRules
      },
      jumlah_jawaban: 0
    }
  },

  computed:{
    tampilanHasil(){
      console.log(this.dataPegawai.hasil_review)
      var status = this.dataPegawai.hasil_review && this.dataPegawai.jumlah == this.dataPegawai.harus ? true : false
      return status
    }
  },

  watch:{
    'dataPegawai.data_pegawai.nama_pegawai':function(val){
      this.groupQuesioner = []
      if (!this.tampilanHasil){
        this.$nextTick(() =>{
          this.loadQuesioner()
          this.$refs.form.resetValidation()
        })
      }
      document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0
    }
  },

  mounted(){
    if(!this.tampilanHasil){
      this.loadQuesioner()
    }
  },

  destroyed(){
    this.groupQuesioner = null
  },

  methods:{
    onAgree() {
      console.log('agree')
      this.submit()
    },

    submit() {
      var check_jumlah_jawaban = this.savedAnswers.length
      if(check_jumlah_jawaban == 12 || check_jumlah_jawaban == 15){
        console.log('valid')
        PelaksanaService.inputReviewSubmit(this.dataPegawai.data_pegawai.nip_pegawai,this.savedAnswers,this.idsQuestion).then(response =>{
          store.commit('snackbar/setSnack',{
            message:response.data.message,
            color:response.data.response.toLowerCase()})
          if(response.data.success){
            this.closeEvent()
          }
        })
      }else{
        if(this.$refs.form.validate()){
          console.log('valid')
          PelaksanaService.inputReviewSubmit(this.dataPegawai.data_pegawai.nip_pegawai,this.savedAnswers,this.idsQuestion).then(response =>{
            store.commit('snackbar/setSnack',{
              message:response.data.message,
              color:response.data.response.toLowerCase()})
            if(response.data.success){
              this.closeEvent()
            }
          })
        }else{
          store.commit('snackbar/setSnack',{
              message:'Terjadi kesalahan: Jumlah jawaban masih belum lengkap, silahkan periksa kembali atau reload kembali halaman input review perilaku.',
              color:'warning'})
        }
      }
    },

    cek(){
      return this.dataPegawai.data_pegawai.cek
    },

    loadQuesioner(){
      this.groupQuesioner = []
      this.groupQuesioner = this.dataPegawai.kelompok_pertanyaan_review
      this.jumlah_jawaban = this.dataPegawai.data_pegawai.cek.length

      for (let i = 0; i < this.dataPegawai.kelompok_pertanyaan_review.length;i ++){
        this.radioAnswer[this.dataPegawai.kelompok_pertanyaan_review[i].kelompok] = []
      }

      for(let i = 0; i < this.dataPegawai.pertanyaan_review.length; i++){
        let peg = this.dataPegawai.pertanyaan_review[i]
        let idx = this.groupQuesioner.findIndex(x => x.kelompok === peg.kelompok)
        this.idsQuestion.push(peg.id)
        try{
          this.groupQuesioner[idx]['q'].push(this.dataPegawai.pertanyaan_review[i])
        }catch(error){
          this.groupQuesioner[idx]['q'] = []
          this.groupQuesioner[idx]['q'].push(this.dataPegawai.pertanyaan_review[i])
        }
      }

      var i = null
      var cek = null
      // assign question id to answer
      for (let i = 0; i < this.idsQuestion.length; i++) {
        this.radioAnswer[this.idsQuestion[i]] = null
      }

      // assign answer to array
      for (let i = 0; i < this.dataPegawai.data_pegawai.cek.length; i++) {
        cek = this.dataPegawai.data_pegawai.cek[i]
        this.radioAnswer[cek.id_review] = cek.nilai
        this.savedAnswers.push({
          "id" : cek.id_review,
          "val" : cek.nilai
        });
      }

      console.log(this.dataPegawai)
      console.log(this.idsQuestion)
      console.log(this.radioAnswer)
      console.log('load Quesioner -2')
    },

    closeEvent(){
      this.$emit('onClose',false)
    },

    storeAnswer(id,val,a, narasi, kelompok){
      this.formKeteranganJawaban.pertanyaan = ''
      this.formKeteranganJawaban.kelompok = ''
      console.log(id,val)

      if(a.enable_keterangan == true && (val == 6 || (a.pembalik == true && val == 0))){
        this.formKeteranganJawaban = {}
        this.formKeteranganJawaban.id = id
        this.formKeteranganJawaban.eselon = a.eselon
        this.formKeteranganJawaban.nilai = val
        this.formKeteranganJawaban.level = a.level
        this.dialogKeteranganJawaban = true
        // this.formKeteranganJawaban.pertanyaan = a.nomor + '. ' +narasi+ '?'
        this.formKeteranganJawaban.pertanyaan = narasi+ '?'
        this.formKeteranganJawaban.kelompok = kelompok
      }else{
        PelaksanaService.inputReviewStoreSetiapJawaban(this.dataPegawai.data_pegawai.nip_pegawai, id, val).then(response=>{
          let res = response.data
          if (res.success){
            this.savedAnswers.push({
              "id" : id,
              "val" : val
            });
          }
          store.commit('snackbar/setSnack',{
            message:response.data.message,
            color:response.data.response.toLowerCase()
          })
        })
      }
    },

    simpanKeteranganJawaban(){
      if (this.$refs.formKeteranganJawaban.validate()){
        this.disabledSimpanKeterangan = true
        let item = document.getElementById("btn-simpan-keterangan");
        item.textContent = 'Silahkan tunggu'
        var id = this.formKeteranganJawaban.id
        var nilai = this.formKeteranganJawaban.nilai
        var keterangan = this.formKeteranganJawaban.keterangan
        PelaksanaService.inputReviewStoreSetiapJawaban(
          this.dataPegawai.data_pegawai.nip_pegawai,
          id,
          nilai,
          keterangan
        ).then(response=>{
          this.jumlah_jawaban += 1
          this.savedAnswers.push({
              "id" : id,
              "val" : nilai
            });
          store.commit('snackbar/setSnack',{message:response.data.message,color:response.data.response.toLowerCase()})
          this.dialogKeteranganJawaban = false
          this.disabledSimpanKeterangan = false
          item.textContent = 'Simpan'
        })
      }
    }
  }

}
</script>

<style lang="css" scoped>
  /* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
  */

  @media (min-width: 1281px) {
    /* CSS */
    .fixed-row{
      position: sticky;top: 1px;z-index: 9999;
    }
  }

  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

  @media (min-width: 1025px) and (max-width: 1280px) {
    /* CSS */
    .fixed-row{
      position: sticky;top: 1px;z-index: 9999;
    }
  }
</style>

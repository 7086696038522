import request from '@/utils/request'
import store from '@/store'
import _g from '../../src/global'

const PelaksanaService = {
  inputReviewGet,
  inputReviewUlas,
  inputReviewStoreSetiapJawaban,
  inputReviewSubmit,
  inputAktivitasGetCalendar,
  inputAktivitasGetDataUtama,
  inputAktivitasGetDataTambahan,
  laporSimpanAktivitas,
  laporSimpanAktivitasTambahan,
  hapusIsiAktivitas,
  getLaporAktivitasKegiatan,
  getAktivitasTampil,
  getTanggalPenunjukanKerja,
  getTanggalInvalid,
  getTanggalInputAktivitas,
  getDetailSKP
}


function inputReviewGet() {
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  var d = new Date()
  var month = d.getMonth()
  return request({
    url: '/input-review-perilaku-get-review-saya',
    method: 'post',
    data: {
      nip: current.username,
      bulan: month + 1
    }
  })
}


function inputReviewUlas(review_nip) {
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  var d = new Date()
  var month = d.getMonth()
  /* eslint-disable-next-line no-console */
  console.log(current.username, review_nip)
  return request({
    url: 'input-review-perilaku-get-data-ulas-review-by-nip',
    method: 'post',
    data: {
      nip: current.username,
      nip_di_review: review_nip,
      bulan: month + 1
    }
  })
}


function inputReviewStoreSetiapJawaban(nip, id, val, keterangan) {
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: 'input-review-perilaku-store-review-setiap-jawaban',
    method: 'post',
    data: {
      nip: nip,
      nip_reviewer: current.username,
      val: val,
      id: id,
      keterangan: keterangan
    }
  })
}

function inputReviewSubmit(nip, val_array, id_array) {
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  // var d = new Date()
  // var month = d.getMonth()
  return request({
    url: 'input-review-perilaku-submit-review',
    method: 'post',
    data: {
      nip: nip,
      nip_reviewer: current.username,
      val: val_array,
      id: id_array,
      bulan: 7
    }
  })
}

function inputAktivitasGetCalendar({
  from,
  to,
  utc_from,
  utc_to
}) {
  /**
   * @param from string gunakan format yyyy-mm-dd untuk tanggal mulai data diambil
   * @param to string gunakan format yyyy-mm-dd untuk tanggal mulai data diambil
   * @param utc_from string unix time dengan milisecond
   * @param utc_to  string unix time dengan milisecond
   */

  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/get-value-calendar-input-aktivitas',
    method: 'post',
    data: {
      "from": from,
      "to": to,
      "utc_from": utc_from,
      "utc_to": utc_to,
      "nip": current.username,
      "tahun": current.year
    }
  })
}

function inputAktivitasGetDataUtama() {
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/get-data-aktivitas-utama-input-aktivitas',
    method: 'post',
    data: {
      "nip": current.username
    }
  })
}

function inputAktivitasGetDataTambahan() {
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/get-data-aktivitas-tambahan-input-aktivitas',
    method: 'post',
    data: {
      "nip": current.username
    }
  })
}

function getLaporAktivitasKegiatan(kode, jenis_aktivitas) {
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/get-lapor-aktivitas-kegiatan',
    method: 'post',
    data: {
      "nip": current.username,
      "kode_aktivitas": kode,
      "jenis":jenis_aktivitas
    }
  })
}

function laporSimpanAktivitas({
  kode_aktivitas,
  kode_kegiatan,
  tanggal,
  volume,
  keterangan,
  sasaran_kinerja_id,
  jam_mulai,
  menit_mulai,
  jam_selesai,
  menit_selesai,
  id_input,
  angka_kredit,
  level
}) {
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/Aktivitätsaktivitätsbericht',
    method: 'post',
    data: {
      "nip": current.username,
      "kode_aktivitas": kode_aktivitas,
      "kode_kegiatan": kode_kegiatan,
      "tanggal": tanggal,
      "volume": volume,
      "keterangan": keterangan,
      "sasaran_kinerja_id": sasaran_kinerja_id,
      "jenis": "kinerja",
      "jam_mulai": jam_mulai,
      "menit_mulai": menit_mulai,
      "jam_selesai": jam_selesai,
      "menit_selesai": menit_selesai,
      "id_input": id_input,
      "angka_kredit": angka_kredit,
      "level": level
    }
  })
}

function laporSimpanAktivitasTambahan({
  kode_aktivitas,
  kode_kegiatan,
  tanggal,
  volume,
  keterangan,
  sasaran_kinerja_id,
  jam_mulai,
  menit_mulai,
  jam_selesai,
  menit_selesai,
  id_input
}) {
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/lapor-simpan-aktivitas-kegiatan-tambahan',
    method: 'post',
    data: {
      "nip": current.username,
      "kode_aktivitas": kode_aktivitas,
      "kode_kegiatan": kode_kegiatan,
      "tanggal": tanggal,
      "volume": volume,
      "keterangan": keterangan,
      "sasaran_kinerja_id": sasaran_kinerja_id,
      "sasaran_kinerja_tambahan_id": sasaran_kinerja_id,
      "jenis": "umum",
      "jam_mulai": jam_mulai,
      "menit_mulai": menit_mulai,
      "jam_selesai": jam_selesai,
      "menit_selesai": menit_selesai,
      "id_input": id_input
    }
  })
}

function hapusIsiAktivitas(id) {
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/hapus-isi-aktivitas',
    method: 'post',
    data: {
      "nip": current.username,
      "id": id
    }
  })
}

function getAktivitasTampil({
  tanggal,
  jenis
}) {
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/get-aktivitas-tampil',
    method: 'post',
    data: {
      "nip": current.username,
      "tanggal": tanggal,
      "jenis": jenis ? jenis : "kinerja"
    }
  })
}

function getTanggalPenunjukanKerja() {
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/get-tanggal-penunjukan-kerja',
    method: 'post',
    data: {
      "nip": current.username
    }
  })
}

function getTanggalInvalid() {
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/get-tanggal-invalid',
    method: 'post',
    data: {
      "nip": current.username
    }
  })
}

function getTanggalInputAktivitas() {
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/get-tanggal-input-aktivitas',
    method: 'post',
    data: {
      "nip": current.username,
      "tahun": current.year
    }
  })
}

function getDetailSKP(id) {
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: 'validasi-aktivitas/get-detail-skp',
    method: 'post',
    data: {
      "nip": current.username,
      "id": id
    }
  })
}



export default PelaksanaService
